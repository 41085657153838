<template>
  <!-- Embeds are behind login on subdomain based cloud instances -->
  <template v-if="!isDefaultCloudInstance">
    <div
      v-if="loadingCurrentUser"
      class="flex flex-1 flex-col items-center justify-center p-4"
    >
      <HoppSmartSpinner class="mb-4" />
    </div>

    <HoppSmartPlaceholder
      v-else-if="currentUser === null"
      :src="`/images/states/${colorMode.value}/login.svg`"
      :alt="t('empty.shared_requests_logout')"
      :text="t('empty.shared_requests_logout')"
    >
      <template #body>
        <HoppButtonPrimary :label="t('auth.login')" @click="showLogin = true" />
      </template>
    </HoppSmartPlaceholder>

    <AppPaneLayout v-else layout-id="embed-primary" :is-embed="true">
      <template #primary>
        <EmbedsHeader :shared-request-u-r-l="sharedRequestURL" />
        <div class="flex flex-col flex-1">
          <EmbedsRequest
            :model-tab="modelTab"
            :shared-request-u-r-l="sharedRequestURL"
          />
          <div class="flex flex-col flex-1">
            <HttpRequestOptions
              v-model="tab.document.request"
              v-model:option-tab="selectedOptionTab"
              :properties="properties"
              :envs="tabRequestVariables"
            />
          </div>
        </div>
      </template>
      <template #secondary>
        <HttpResponse :document="tab.document" :is-embed="true" />
      </template>
    </AppPaneLayout>

    <FirebaseLogin v-if="showLogin" @hide-modal="showLogin = false" />
  </template>
</template>

<script lang="ts" setup>
import { useI18n } from "@hoppscotch/common/composables/i18n"
import { useReadonlyStream } from "@hoppscotch/common/composables/stream"
import { useColorMode } from "@hoppscotch/common/composables/theming"
import { HoppRequestDocument } from "@hoppscotch/common/helpers/rest/document"
import { platform } from "@hoppscotch/common/platform"
import { HoppTab } from "@hoppscotch/common/services/tab"
import { computed, ref, useModel } from "vue"

import {
  getOrganizationDomain,
  getRootDomain,
  isDefaultCloudInstance,
} from "../helpers/utils/cloud-for-orgs"

type RESTOptionTabs = (typeof VALID_OPTION_TABS)[number]

const props = defineProps<{
  modelTab: HoppTab<HoppRequestDocument>
  properties: RESTOptionTabs[]
  sharedRequestID: string
}>()

const tab = useModel(props, "modelTab")

const selectedOptionTab = ref<RESTOptionTabs>(props.properties[0])

const organizationDomain = getOrganizationDomain()

const showLogin = ref(false)

const currentUser = useReadonlyStream(
  platform.auth.getCurrentUserStream(),
  platform.auth.getCurrentUser()
)
const probableUser = useReadonlyStream(
  platform.auth.getProbableUserStream(),
  platform.auth.getProbableUser()
)

const t = useI18n()
const colorMode = useColorMode()

const VALID_OPTION_TABS = [
  "params",
  "bodyParams",
  "headers",
  "authorization",
  "preRequestScript",
  "tests",
  "requestVariables",
]

const loadingCurrentUser = computed(() => {
  if (!probableUser.value) return false
  else if (!currentUser.value) return true
  return false
})

const shortcodeBaseURL = computed(() => {
  const rootDomain = getRootDomain()
  return `https://${organizationDomain}.${rootDomain}`
})

const sharedRequestURL = computed(() => {
  return `${shortcodeBaseURL.value}/e/${props.sharedRequestID}`
})

const tabRequestVariables = computed(() => {
  return tab.value.document.request.requestVariables.map(({ key, value }) => ({
    key,
    value,
    secret: false,
    sourceEnv: "RequestVariable",
  }))
})
</script>
