<template>
  <span v-if="team.orgDomain !== organizationDomain">
    under
    <span class="font-extrabold">
      {{ instanceInfo }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { Team } from "../helpers/backend/graphql"
import {
  getOrganizationDomain,
  getRootDomain,
} from "../helpers/utils/cloud-for-orgs"

const props = defineProps<{
  team: Team
}>()

const organizationDomain = getOrganizationDomain()

const rootDomain = getRootDomain()

const instanceInfo = computed(() =>
  props.team.orgName
    ? `${props.team.orgName} (${props.team.orgDomain}.${rootDomain})`
    : `the cloud instance (${rootDomain})`
)
</script>
