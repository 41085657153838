import {
  AuthEvent,
  AuthPlatformDef,
  HoppUser,
} from "@hoppscotch/common/platform/auth"
import {
  Subscription,
  BehaviorSubject,
  Subject,
  filter,
  map,
  combineLatest,
} from "rxjs"
import { setDoc, onSnapshot, doc, getFirestore } from "firebase/firestore"
import { getApp } from "firebase/app"
import {
  User as FBUser,
  getAuth,
  isSignInWithEmailLink as isSignInWithEmailLinkFB,
  signInWithEmailLink as signInWithEmailLinkFB,
  sendEmailVerification,
  updateEmail,
  updateProfile,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth"
import { PersistenceService } from "@hoppscotch/common/services/persistence"
import { getService } from "@hoppscotch/common/modules/dioc"
import * as E from "fp-ts/Either"
import CustomLogin from "../../../components/Login.vue"

export const currentUserFB$ = new BehaviorSubject<FBUser | null>(null)
export const authEvents$ = new Subject<AuthEvent>()
export const probableUser$ = new BehaviorSubject<HoppUser | null>(null)

const authIdToken$ = new BehaviorSubject<string | null>(null)

async function signInWithEmailLink(email: string, url: string) {
  return await signInWithEmailLinkFB(getAuth(), email, url)
}

function fbUserToHoppUser(user: FBUser): HoppUser {
  return {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
  }
}

const currentUser$ = new BehaviorSubject<HoppUser | null>(null)

const persistenceService = getService(PersistenceService)

async function setUser(user: HoppUser | null) {
  currentUser$.next(user)
  probableUser$.next(user)

  await persistenceService.setLocalConfig("login_state", JSON.stringify(user))
}

/**
 * Reauthenticate the user with the given credential
 */
async function reauthenticateUser() {
  // TODO: Figure out reauthentication
}

export function getUserFirebaseMetadata() {
  return currentUserFB$.value?.metadata
}

export const def: AuthPlatformDef = {
  customLoginSelectorUI: CustomLogin,
  getCurrentUserStream: () => currentUser$,
  getAuthEventsStream: () => authEvents$,
  getProbableUserStream: () => probableUser$,

  getCurrentUser: () => currentUser$.value,
  getProbableUser: () => probableUser$.value,

  getBackendHeaders() {
    return {
      authorization: `Bearer ${authIdToken$.value}`,
    }
  },
  axiosPlatformConfig() {
    return {
      headers: {
        Authorization: `Bearer ${authIdToken$.value}`,
      },
    }
  },
  willBackendHaveAuthError() {
    return !authIdToken$.value
  },
  onBackendGQLClientShouldReconnect(func) {
    authIdToken$.subscribe(() => {
      func()
    })
  },
  getDevOptsBackendIDToken() {
    return authIdToken$.value
  },
  async performAuthInit() {
    // This is a desktop app specific hack because Firebase for some reason
    // does some weird things with the app on linux. Ionic folks had this issue
    // and solved it this way: https://github.com/firebase/firebase-js-sdk/issues/5019#issuecomment-924054530
    // Firebase is sooooo stupid :/
    const auth = initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence,
    })
    const firestore = getFirestore()

    combineLatest([currentUserFB$, authIdToken$])
      .pipe(
        map(([user, token]) => {
          // If there is no auth token, we will just consider as the auth as not complete
          if (token === null) return null
          if (user !== null) return fbUserToHoppUser(user)
          return null
        })
      )
      .subscribe((x) => {
        currentUser$.next(x)
      })

    let extraSnapshotStop: (() => void) | null = null

    probableUser$.next(
      JSON.parse(
        (await persistenceService.getLocalConfig("login_state")) ?? "null"
      )
    )

    onAuthStateChanged(auth, async (user) => {
      const wasLoggedIn = currentUser$.value !== null

      if (user) {
        probableUser$.next(user)
      } else {
        probableUser$.next(null)

        await persistenceService.removeLocalConfig("login_state")
      }

      if (!user && extraSnapshotStop) {
        extraSnapshotStop()
        extraSnapshotStop = null
      } else if (user) {
        // Merge all the user info from all the authenticated providers
        user.providerData.forEach((profile) => {
          if (!profile) return

          const us = {
            updatedOn: new Date(),
            provider: profile.providerId,
            name: profile.displayName,
            email: profile.email,
            photoUrl: profile.photoURL,
            uid: profile.uid,
          }

          setDoc(doc(firestore, "users", user.uid), us, { merge: true }).catch(
            (e) => console.error("error updating", us, e)
          )
        })

        extraSnapshotStop = onSnapshot(
          doc(firestore, "users", user.uid),
          (doc) => {
            const data = doc.data()

            const userUpdate: HoppUser = fbUserToHoppUser(user)

            if (data) {
              // Write extra provider data
              userUpdate.provider = data.provider
              userUpdate.accessToken = data.accessToken
            }

            currentUser$.next(userUpdate)
          }
        )
      }

      currentUserFB$.next(user)
      currentUser$.next(user === null ? null : fbUserToHoppUser(user))

      // User wasn't found before, but now is there (login happened)
      if (!wasLoggedIn && user) {
        authEvents$.next({
          event: "login",
          user: currentUser$.value!,
        })
      } else if (wasLoggedIn && !user) {
        // User was found before, but now is not there (logout happened)
        authEvents$.next({
          event: "logout",
        })
      }
    })

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        authIdToken$.next(await user.getIdToken())

        await persistenceService.setLocalConfig(
          "login_state",
          JSON.stringify(user)
        )
      } else {
        authIdToken$.next(null)
      }
    })
  },

  waitProbableLoginToConfirm() {
    return new Promise<void>((resolve, reject) => {
      if (authIdToken$.value) resolve()

      if (!probableUser$.value) reject(new Error("no_probable_user"))

      let sub: Subscription | null = null
      sub = authIdToken$.pipe(filter((token) => !!token)).subscribe(() => {
        sub?.unsubscribe()
        resolve()
      })
    })
  },

  async signInWithEmail() {
    throw new Error("Not applicable")
  },

  isSignInWithEmailLink(url: string) {
    return isSignInWithEmailLinkFB(getAuth(), url)
  },

  async verifyEmailAddress() {
    if (!currentUserFB$.value) throw new Error("No user has logged in")

    try {
      await sendEmailVerification(currentUserFB$.value)
    } catch (e) {
      console.error("error verifying email address", e)
      throw e
    }
  },
  async signInUserWithGoogle() {
    throw new Error("Not applicable")
  },
  async signInUserWithGithub() {
    throw new Error("Not applicable")
  },
  async signInUserWithMicrosoft() {
    throw new Error("Not applicable")
  },
  async signInWithEmailLink(email: string, url: string) {
    await signInWithEmailLinkFB(getAuth(), email, url)
  },
  async setEmailAddress(email: string) {
    if (!currentUserFB$.value) throw new Error("No user has logged in")

    try {
      await updateEmail(currentUserFB$.value, email)
    } catch (e) {
      await reauthenticateUser()
      console.log("error setting email address", e)
      throw e
    }
  },
  async setDisplayName(name: string) {
    if (!name) return E.left("USER_NAME_CANNOT_BE_EMPTY")
    if (!currentUserFB$.value) return E.left("NO_USER_LOGGED_IN")

    const us = {
      displayName: name,
    }

    try {
      await updateProfile(currentUserFB$.value, us)
      setUser({
        ...fbUserToHoppUser(currentUserFB$.value),
        displayName: name,
      })
      return E.right(undefined)
    } catch (e) {
      console.error("error updating display name", e)
      return E.left(e)
    }
  },
  async signOutUser() {
    if (!currentUser$.value) throw new Error("No user has logged in")

    await signOut(getAuth())
  },
  async processMagicLink() {
    if (this.isSignInWithEmailLink(window.location.href)) {
      let email = await persistenceService.getLocalConfig("emailForSignIn")

      if (!email) {
        email = window.prompt(
          "Please provide your email for confirmation"
        ) as string
      }

      await signInWithEmailLink(email, window.location.href)

      await persistenceService.removeLocalConfig("emailForSignIn")
      window.location.href = "/"
    }
  },
  getAllowedAuthProviders() {
    return Promise.resolve(E.right(["GITHUB", "GOOGLE", "MICROSOFT", "EMAIL"]))
  },
}
