import { until } from "@vueuse/core"
import { Service } from "dioc"
import * as E from "fp-ts/Either"
import { ref } from "vue"

import { runGQLQuery } from "@hoppscotch/common/helpers/backend/GQLClient"
import {
  BANNER_PRIORITY_HIGH,
  BANNER_PRIORITY_MEDIUM,
  BannerService,
  BannerType,
} from "@hoppscotch/common/services/banner.service"
import {
  GetOrganizationInfoByDomainDocument,
  GetOrganizationInfoByIdDocument,
  Organization,
  OrganizationStatus,
} from "../../src/helpers/backend/graphql"
import {
  getOrganizationDomain,
  isDefaultCloudInstance,
} from "../../src/helpers/utils/cloud-for-orgs"

/**
 * Returns the banner type based on the number of days remaining for the license to expire
 */
const getLicenseBannerType = (daysRemaining: number): BannerType => {
  if (daysRemaining < 0) {
    return "error"
  }
  if (daysRemaining < 4) {
    return "warning"
  }
  return "info"
}

/**
 * This service compiles the necessary business logic wrt org cloud instances.
 */
export class OrganizationService extends Service {
  public static readonly ID = "ORGANIZATION_SERVICE"

  private readonly banner = this.bind(BannerService)

  private organizationInfo = ref<Organization | null>(null)

  // TODO: Remove the below private member when the result is returned directly as an Either
  private organizationError = ref<string | null>(null)

  override async onServiceInit() {
    await this.fetchOrganizationBaseInfo()
  }

  private async displaySubscriptionStatusBanner() {
    await this.fetchOrganizationInfo(true)

    if (
      this.organizationError.value !== null ||
      this.organizationInfo.value === null
    ) {
      return
    }

    if (this.organizationInfo.value.orgStatus === OrganizationStatus.Canceled) {
      this.banner.showBanner({
        type: "error",
        text: () =>
          "Your subscription has been canceled. Please contact support.",
        score: BANNER_PRIORITY_HIGH,
        dismissible: true,
      })

      return
    }

    if (this.organizationInfo.value.nextBillingDate) {
      const expiry = new Date(
        this.organizationInfo.value.nextBillingDate * 1000
      )
      const now = new Date()

      // Normalize to UTC midnight to avoid time zone differences affecting the calculation
      expiry.setHours(0, 0, 0, 0)
      now.setHours(0, 0, 0, 0)

      const diffInDays = Math.round(
        (expiry.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      )

      if (diffInDays > 10) {
        return
      }

      const bannerType = getLicenseBannerType(diffInDays)

      const bannerText =
        diffInDays < 0
          ? "Your subscription has expired. To continue using Hoppscotch Orgs, please renew your subscription."
          : `Reminder: Your subscription will expire in ${diffInDays} ${
              diffInDays > 1 ? "days" : "day"
            }. Please resubscribe to continue using Hoppscotch Orgs.`

      this.banner.showBanner({
        type: bannerType,
        text: () => bannerText,
        score: BANNER_PRIORITY_MEDIUM,
        dismissible: true,
      })
    }
  }

  public async fetchOrganizationBaseInfo() {
    if (this.organizationError.value) {
      this.organizationError.value = null
    }

    const orgDomain = getOrganizationDomain()

    if (this.organizationInfo.value || !orgDomain) {
      return
    }

    const result = await runGQLQuery({
      query: GetOrganizationInfoByDomainDocument,
      variables: { orgDomain },
    })

    if (E.isRight(result)) {
      this.organizationInfo.value = <Organization>{
        ...(this.organizationInfo.value ?? {}),
        orgID: result.right.organizationByDomain,
      }
      this.organizationError.value = null

      await this.displaySubscriptionStatusBanner()
    } else {
      this.organizationError.value = result.left.error.toString()
    }
  }

  public async fetchOrganizationInfo(refetch = false) {
    if (this.organizationError.value) {
      this.organizationError.value = null
    }

    // Org ID needs to be in place before fetching the organization info
    await until(this.organizationInfo).toMatch((info) => info !== null, {
      timeout: 3000,
    })

    if (!this.organizationInfo.value) {
      // TODO: Maintain a list of error codes
      this.organizationError.value = "organization/base_info_not_available"
      return
    }

    // Prevent n/w call if the required information is already available
    const { orgID, orgDomain, orgName } = this.organizationInfo.value

    if (!refetch && orgID && orgDomain && orgName) {
      this.organizationInfo.value
      return
    }

    const result = await runGQLQuery({
      query: GetOrganizationInfoByIdDocument,
      // TODO: Supply actual domain
      variables: { orgID: this.organizationInfo.value.orgID },
    })

    // TODO: Return the either directly
    if (E.isRight(result)) {
      this.organizationInfo.value = result.right.organization as Organization
      this.organizationError.value = null
    } else {
      this.organizationError.value = result.left.error.toString()
    }
  }

  public getOrganizationID(): string | null {
    if (isDefaultCloudInstance) {
      return null
    }

    return this.organizationInfo.value?.orgID ?? null
  }

  public async getOrganizationInfo(): Promise<
    (Organization & { error: string | null }) | null
  > {
    if (isDefaultCloudInstance) {
      return null
    }

    await this.fetchOrganizationInfo()

    // TODO: This method might not be necessary if the result is returned directly as an Either above
    return {
      ...(this.organizationInfo.value as Organization),
      error: this.organizationError.value,
    }
  }

  public clearOrganizationInfo() {
    const { orgID } = this.organizationInfo.value ?? {}

    this.organizationInfo.value = orgID ? <Organization>{ orgID } : null
  }
}
