export const isDefaultCloudInstance =
  window.location.hostname === "hoppscotch.io" ||
  // Cloud local instance
  window.location.hostname === "localhost" ||
  window.location.hostname === "default.hoppscotch.io" ||
  // Cloud staging instance
  window.location.hostname === "cloud-staging.deploychan.hoppscotch.com" ||
  // Cloud orgs staging instance
  window.location.hostname === "cloud-orgs-staging.deploychan.hoppscotch.com"

export const getOrganizationDomain = () => {
  if (isDefaultCloudInstance) {
    return null
  }

  return window.location.hostname.split(".")[0]
}

export const getRootDomain = () => {
  // TODO: Return `null`
  // Current usages have safeguards present wrt `isDefaultCloudInstance` check
  if (isDefaultCloudInstance) {
    return ""
  }

  if (window.location.hostname === "localhost") {
    return "hoppscotch.io"
  }

  return window.location.hostname.split(".").slice(1).join(".")
}
