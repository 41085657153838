import { nextTick, ref, watch } from "vue"
import { emit, listen } from "@tauri-apps/api/event"

import { createHoppApp } from "@hoppscotch/common"
import { useSettingStatic } from "@hoppscotch/common/composables/settings"

import { def as webAuth } from "@platform/auth/web"
import { def as webEnvironments } from "@platform/environments/web"
import { def as webCollections } from "@platform/collections/web"
import { def as webSettings } from "@platform/settings/web"
import { def as webHistory } from "@platform/history/web"

import { def as backendDef } from "./backend.platform"
import { def as organizationDef } from "./organization.platform"

import { def as desktopAuth } from "@platform/auth/desktop"
import { def as desktopEnvironments } from "@platform/environments/desktop"
import { def as desktopCollections } from "@platform/collections/desktop"
import { def as desktopSettings } from "@platform/settings/desktop"
import { def as desktopHistory } from "@platform/history/desktop"

import { sentryModule as sentryModuleDesktop } from "@platform/sentry/desktop"
import { sentryModule as sentryModuleWeb } from "@platform/sentry/web"

import { stdFooterItems } from "@hoppscotch/common/platform/std/ui/footerItem"
import { stdSupportOptionItems } from "@hoppscotch/common/platform/std/ui/supportOptionsItem"
import { getKernelMode } from "@hoppscotch/kernel"
import { kernelIO } from "@hoppscotch/common/platform/std/kernel-io"

import { NativeKernelInterceptorService } from "@hoppscotch/common/platform/std/kernel-interceptors/native"
import { AgentKernelInterceptorService } from "@hoppscotch/common/platform/std/kernel-interceptors/agent"
import { ProxyKernelInterceptorService } from "@hoppscotch/common/platform/std/kernel-interceptors/proxy"
import { ExtensionKernelInterceptorService } from "@hoppscotch/common/platform/std/kernel-interceptors/extension"
import { BrowserKernelInterceptorService } from "@hoppscotch/common/platform/std/kernel-interceptors/browser"

// Purely `cloud`
import { DocumentationSearcherService } from "@platform-services/spotlight/searchers/documentation.searcher"
import { OrganizationService } from "@platform-services/organization.service"

import { def as webExperiments } from "@platform/experiments/web"
import {
  def as webAnalytics,
  addPosthogIgnoreClass as webIgnore,
} from "@platform/analytics/web"

import { def as desktopExperiments } from "@platform/experiments/desktop"
import {
  def as desktopAnalytics,
  addPosthogIgnoreClass as desktopIgnore,
} from "@platform/analytics/desktop"

import {
  chatWithUsFooterMenuItem,
  chatWithUsSupportOptionMenuItem,
  crispModule,
} from "./crisp"

import OrganizationModule from "./organization"

import { initializeFirebase } from "./firebase/init"
import { isDefaultCloudInstance } from "@platform-helpers/utils/cloud-for-orgs"
import { getService } from "@hoppscotch/common/modules/dioc"

import Newsletter from "./components/Newsletter.vue"
import UserDeactivate from "./components/UserDeactivate.vue"
import UserDeletionSoleTeamOwnerInfo from "./components/UserDeletionSoleTeamOwnerInfo.vue"
import CustomEmbeds from "./components/CustomEmbeds.vue"

// App
initializeFirebase()

type Platform = "web" | "desktop"

const createPlatformDef = <Web, Desktop>(web: Web, desktop: Desktop) => ({
  web,
  desktop,
  get: (platform: Platform) => (platform === "web" ? web : desktop),
})

const webInterceptors = [
  BrowserKernelInterceptorService,
  ProxyKernelInterceptorService,
  AgentKernelInterceptorService,
  ExtensionKernelInterceptorService,
]

const desktopInterceptors = [
  NativeKernelInterceptorService,
  ProxyKernelInterceptorService,
]

const webModules = [
  ...[isDefaultCloudInstance ? crispModule : []],
  sentryModuleWeb,
  crispModule,
  OrganizationModule,
]

const desktopModules = [sentryModuleDesktop]

const webMenuItems = [chatWithUsFooterMenuItem, ...stdFooterItems]
const desktopMenuItems = [...stdFooterItems]

const webSupportItems = [
  chatWithUsSupportOptionMenuItem,
  ...stdSupportOptionItems,
]
const desktopSupportItems = [...stdSupportOptionItems]

const webProfileSections = [
  Newsletter,
  ...[isDefaultCloudInstance ? [] : UserDeactivate],
]
const desktopProfileSections: any[] = []

const webUserDeletionSoleTeamOwnerInfo = UserDeletionSoleTeamOwnerInfo
const desktopUserDeletionSoleTeamOwnerInfo = null

const webEmbedsComponent = CustomEmbeds
const desktopEmbedsComponent = null

const platformDefs = {
  auth: createPlatformDef(webAuth, desktopAuth),
  environments: createPlatformDef(webEnvironments, desktopEnvironments),
  collections: createPlatformDef(webCollections, desktopCollections),
  settings: createPlatformDef(webSettings, desktopSettings),
  history: createPlatformDef(webHistory, desktopHistory),
  experiments: createPlatformDef(webExperiments, desktopExperiments),
  interceptors: createPlatformDef(webInterceptors, desktopInterceptors),
  analytics: createPlatformDef(webAnalytics, desktopAnalytics),
  ignore: createPlatformDef(webIgnore, desktopIgnore),
  modules: createPlatformDef(webModules, desktopModules),
  menuItems: createPlatformDef(webMenuItems, desktopMenuItems),
  supportItems: createPlatformDef(webSupportItems, desktopSupportItems),
  profileSections: createPlatformDef(
    webProfileSections,
    desktopProfileSections
  ),
  userDeletionSoleTeamOwnerInfo: createPlatformDef(
    webUserDeletionSoleTeamOwnerInfo,
    desktopUserDeletionSoleTeamOwnerInfo
  ),
  embedsComponent: createPlatformDef(
    webEmbedsComponent,
    desktopEmbedsComponent
  ),
  organization: createPlatformDef(organizationDef, null),
}

const kernelMode = getKernelMode()
const headerPaddingLeft = ref("0px")
const headerPaddingTop = ref("0px")

const getInterceptors = (mode: Platform) =>
  platformDefs.interceptors.get(mode).map((service) => ({
    type: "service" as const,
    service,
  }))

async function initApp() {
  await createHoppApp("#app", {
    ui: {
      additionalFooterMenuItems: platformDefs.menuItems.get(kernelMode),
      additionalSupportOptionsMenuItems:
        platformDefs.supportItems.get(kernelMode),
      onCodemirrorInstanceMount: platformDefs.ignore.get(kernelMode),
      additionalProfileSections: platformDefs.profileSections.get(kernelMode),
      appHeader: {
        paddingLeft: headerPaddingLeft,
        paddingTop: headerPaddingTop,
      },
      additionalUserDeletionSoleTeamOwnerInfo:
        platformDefs.userDeletionSoleTeamOwnerInfo.get(kernelMode),
      additionalEmbedsComponent: platformDefs.embedsComponent.get(kernelMode),
    },
    addedHoppModules: platformDefs.modules.get(kernelMode),
    analytics: platformDefs.analytics.get(kernelMode),
    auth: platformDefs.auth.get(kernelMode),
    kernelIO,
    sync: {
      environments: platformDefs.environments.get(kernelMode),
      collections: platformDefs.collections.get(kernelMode),
      settings: platformDefs.settings.get(kernelMode),
      history: platformDefs.history.get(kernelMode),
    },
    kernelInterceptors: {
      default: kernelMode === "desktop" ? "native" : "browser",
      interceptors: getInterceptors(kernelMode),
    },
    limits: {
      collectionImportSizeLimit: 50,
    },
    spotlight: {
      additionalSearchers: [DocumentationSearcherService],
    },
    platformFeatureFlags: {
      exportAsGIST: true,
      hasTelemetry: true,
      cookiesEnabled: kernelMode === "desktop",
      promptAsUsingCookies: false,
      workspaceSwitcherLogin: ref(false),
    },
    experiments: platformDefs.experiments.get(kernelMode),
    backend: backendDef,
    organization: platformDefs.organization.get(kernelMode),
    additionalInspectors: [
      { type: "service", service: ExtensionKernelInterceptorService },
    ],
  })

  // TODO: Specify via `addedServices` field above
  if (kernelMode === "web" && !isDefaultCloudInstance) {
    getService(OrganizationService)
  }

  if (kernelMode === "desktop") {
    listen("will-enter-fullscreen", () => {
      headerPaddingTop.value = "0px"
      headerPaddingLeft.value = "0px"
    })

    listen("will-exit-fullscreen", () => {
      headerPaddingTop.value = "0px"
      headerPaddingLeft.value = "80px"
    })

    headerPaddingTop.value = "0px"
    headerPaddingLeft.value = "80px"

    // Add backspace prevention for non-text inputs
    window.addEventListener(
      "keydown",
      function (e) {
        if (e.key === "Backspace" && !isTextInput(e.target)) {
          e.preventDefault()
        }
      },
      true
    )

    // Watch background color changes
    watch(
      useSettingStatic("BG_COLOR")[0],
      async () => {
        await nextTick()
        await emit(
          "hopp-bg-changed"
          // TODO: Look into why this is causing
          //    "fatal runtime error: Rust cannot catch foreign exceptions"
          //
          // getComputedStyle(document.documentElement).getPropertyValue(
          //   "--primary-color"
          // )
        )
      },
      { immediate: true }
    )
  }
}

function isTextInput(target: EventTarget | null) {
  if (target instanceof HTMLInputElement) {
    return (
      target.type === "text" ||
      target.type === "email" ||
      target.type === "password" ||
      target.type === "number" ||
      target.type === "search" ||
      target.type === "tel" ||
      target.type === "url" ||
      target.type === "textarea"
    )
  } else if (target instanceof HTMLTextAreaElement) {
    return true
  } else if (target instanceof HTMLElement && target.isContentEditable) {
    return true
  }
  return false
}

initApp().catch(console.error)
