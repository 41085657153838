<template>
  <section class="p-4">
    <h4 class="font-semibold text-secondaryDark">Newsletter</h4>
    <p class="my-1 mb-4 text-secondaryLight">
      Subscribe to our newsletter to get updates on new features and more.
      You'll be subscribed with the email you're logged in with.
    </p>

    <button
      class="inline-flex items-center justify-center font-semibold transition whitespace-nowrap focus:outline-none text-secondary hover:text-secondaryDark focus-visible:text-secondaryDark rounded px-4 py-2 border border-divider hover:border-dividerDark focus-visible:border-dividerDark bg-primaryLight hover:bg-primaryDark focus-visible:bg-primaryDark"
      :disabled="isLoading"
      @click="
        isSubscribed ? deleteNewsletterSubscription() : subscribeNewsletter()
      "
    >
      {{ buttonText }}
    </button>
  </section>
</template>

<script setup lang="ts">
import { useReadonlyStream } from "@hoppscotch/common/composables/stream"
import axios, { AxiosError } from "axios"
import { computed, ref, watch } from "vue"
import { authIdToken$ } from "@platform/auth/web"

const authIdToken = useReadonlyStream(authIdToken$, null)

const endpoint = `${import.meta.env.VITE_BACKEND_API_URL}/newsletter/subscriber`

const isSubscribed = ref(false)
const isLoading = ref(true)

const buttonText = computed(() => {
  if (isLoading.value) {
    return "Loading..."
  } else if (isSubscribed.value) {
    return "Unsubscribe"
  } else {
    return "Subscribe"
  }
})

watch(
  authIdToken,
  (token) => {
    if (token) {
      getSubscriptionDetails()
    }
  },
  { immediate: true }
)

async function subscribeNewsletter() {
  isLoading.value = true
  try {
    await axios.post(
      endpoint,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authIdToken.value!}`,
        },
      }
    )
    isLoading.value = false
    isSubscribed.value = true
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      isSubscribed.value = true
    }
    isLoading.value = false
  }
}

async function getSubscriptionDetails() {
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authIdToken.value!}`,
      },
    })
    isLoading.value = false
    isSubscribed.value = response.data.status === "enabled"
  } catch (error) {
    isLoading.value = false
    if (error instanceof AxiosError && error.response?.status === 404) {
      isSubscribed.value = false
    }
  }
}

async function deleteNewsletterSubscription() {
  isLoading.value = true
  try {
    await axios.delete(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authIdToken.value!}`,
      },
    })
    isSubscribed.value = false
    isLoading.value = false
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 400) {
      isSubscribed.value = false
    }
    isLoading.value = false
  }
}
</script>
