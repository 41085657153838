<template>
  <section class="p-4">
    <h4 class="font-semibold text-secondaryDark">
      Deactivate Account on this organization
    </h4>
    <div class="my-1 mb-4 text-secondaryLight">
      Your data will be saved and restored once you join this organization
      again.
    </div>
    <HoppButtonSecondary
      filled
      outline
      label="Deactivate Account"
      type="submit"
      @click="showDeactivateAccountModal = true"
    />
    <HoppSmartModal
      v-if="showDeactivateAccountModal"
      dialog
      title="Deactivate Account on this organization"
      @close="showDeactivateAccountModal = false"
    >
      <template #body>
        <div v-if="loading" class="flex flex-col items-center justify-center">
          <HoppSmartSpinner class="mb-4" />
          <span class="text-secondaryLight">{{ t("state.loading") }}</span>
        </div>

        <div
          v-else-if="myTeams.length"
          class="bg-bannerInfo flex flex-col space-y-2 rounded-lg border border-red-500 p-4 text-secondaryDark"
        >
          <h2 class="font-bold text-red-500">
            {{ t("error.danger_zone") }}
          </h2>
          <div>
            {{ t("error.delete_account") }}
            <ul class="my-4 ml-8 list-disc space-y-2">
              <li v-for="team in myTeams" :key="team.id">
                {{ team.name }}
              </li>
            </ul>
            <span class="font-semibold">
              You must either remove yourself, transfer ownership, or delete
              these workspaces before you can deactivate your account.
            </span>
          </div>
        </div>

        <div v-else>
          <div
            class="bg-bannerInfo mb-4 flex flex-col space-y-2 rounded-lg border border-amber-500 p-4 text-secondaryDark"
          >
            <div class="font-medium text-secondaryDark">
              Your data will be saved and restored once you join this
              organization again.
            </div>
          </div>
          <div class="flex flex-col">
            <input
              id="deactivateUserAccount"
              v-model="userVerificationInput"
              class="input floating-input"
              placeholder=" "
              type="text"
              autocomplete="off"
            />
            <label for="deactivateUserAccount">
              Type
              <span class="font-bold"> deactivate my account </span>
              to confirm
            </label>
          </div>
        </div>
      </template>
      <template #footer>
        <span class="flex space-x-2">
          <HoppButtonPrimary
            :loading="deactivatingUser"
            label="Deactivate Account"
            filled
            outline
            :disabled="
              loading ||
              myTeams.length > 0 ||
              userVerificationInput !== 'deactivate my account'
            "
            class="!hover:bg-amber-600 !hover:border-amber-600 !border-amber-500 !bg-amber-500"
            @click="deactivateUserAccount"
          />
          <HoppButtonSecondary
            :label="t('action.cancel')"
            outline
            filled
            @click="showDeactivateAccountModal = false"
          />
        </span>
      </template>
    </HoppSmartModal>
  </section>
</template>

<script setup lang="ts">
import * as E from "fp-ts/Either"
import { onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"

import { useI18n } from "@hoppscotch/common/composables/i18n"
import { useReadonlyStream } from "@hoppscotch/common/composables/stream"
import { useToast } from "@hoppscotch/common/composables/toast"
import { runMutation } from "@hoppscotch/common/helpers/backend/GQLClient"
import {
  DeactivateOrganizationMemberDocument,
  GetMyTeamsQuery,
} from "../helpers/backend/graphql"
import { platform } from "@hoppscotch/common/platform"

const currentUser = useReadonlyStream(
  platform.auth.getCurrentUserStream(),
  platform.auth.getCurrentUser()
)
const t = useI18n()
const toast = useToast()
const router = useRouter()

const showDeactivateAccountModal = ref(false)
const userVerificationInput = ref("")

const loading = ref(true)
const myTeams = ref<GetMyTeamsQuery["myTeams"]>([])

let organizationID = ""

// Fetch `orgID` for org-cloud instances
onMounted(async () => {
  const { organization } = platform

  if (!organization || organization.isDefaultCloudInstance) {
    return
  }

  const orgInfo = await organization.getOrgInfo()

  if (orgInfo) {
    organizationID = orgInfo.orgID
  }
})

watch(showDeactivateAccountModal, (isModalOpen) => {
  if (isModalOpen) {
    fetchMyTeams()
  }
})

const fetchMyTeams = async () => {
  loading.value = true

  const result = await platform.backend.getUserTeams()

  loading.value = false

  if (E.isLeft(result)) {
    throw new Error(
      `Failed fetching teams list: ${JSON.stringify(result.left)}`
    )
  }

  myTeams.value = result.right.myTeams.filter((team) => {
    return team.ownersCount === 1 && team.myRole === "OWNER"
  })
}

const deactivatingUser = ref(false)

const deactivateUserAccount = async () => {
  if (deactivatingUser.value || !currentUser.value) {
    return
  }

  deactivatingUser.value = true

  const result = await runMutation(DeactivateOrganizationMemberDocument, {
    orgMemberID: currentUser.value.uid,
    orgID: organizationID,
  })()

  if (E.isLeft(result)) {
    const errorMsg = result.left.error.toString()

    // TODO: i18n strings
    if (errorMsg.includes("organization/organization_member_is_admin")) {
      toast.error("Please remove Admin access before attempting deactivation.")
    } else if (errorMsg.includes("organization/user_is_owner")) {
      toast.error(
        "The user is sole owner of a team. Please transfer ownership before deactivation."
      )
    } else {
      toast.error(t("error.something_went_wrong"))
    }

    deactivatingUser.value = false

    return
  }

  deactivatingUser.value = false
  showDeactivateAccountModal.value = false

  toast.success("Your account has been deactivated")

  platform.auth.signOutUser()
  router.push(`/`)
}
</script>
