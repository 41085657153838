import { useStreamStatic } from "@hoppscotch/common/composables/stream"
import { getService } from "@hoppscotch/common/modules/dioc"
import { platform } from "@hoppscotch/common/platform"
import {
  PersistenceService,
  STORE_KEYS,
} from "@hoppscotch/common/services/persistence"
import { HoppModule } from "@hoppscotch/common/src/modules"
import { computed } from "vue"

import ErrorPage from "./components/ErrorPage.vue"
import { isDefaultCloudInstance } from "@platform-helpers/utils/cloud-for-orgs"
import { OrganizationService } from "@platform-services/organization.service"

export default <HoppModule>{
  async onBeforeRouteChange(to, _from, router) {
    const currentUser = useStreamStatic(
      platform.auth.getCurrentUserStream(),
      platform.auth.getCurrentUser(),
      () => {
        /* noop */
      }
    )[0]

    const probableUser = useStreamStatic(
      platform.auth.getProbableUserStream(),
      platform.auth.getProbableUser(),
      () => {
        /* noop */
      }
    )[0]

    const loadingCurrentUser = computed(() => {
      if (!probableUser.value) {
        return false
      }

      if (!currentUser.value) {
        return true
      }

      return false
    })

    // Cases applicable to the default cloud instance `hoppscotch.io`
    if (isDefaultCloudInstance) {
      // `/admin` & `/join-org` routes exist only in the org cloud instance
      // /orgs/success & /orgs/invalid routes only applies to subdomain based instances
      if (
        (to.path.startsWith("/admin") ||
          to.path.startsWith("/join-org") ||
          to.path.startsWith("/orgs/")) &&
        to.matched[0].components
      ) {
        to.matched[0].components.default = ErrorPage

        return
      }

      // `/orgs` route is expected to be visited by a logged-in user
      if (
        !loadingCurrentUser.value &&
        !currentUser.value &&
        to.name === "orgs" &&
        to.matched[0].components
      ) {
        to.matched[0].components.default = ErrorPage

        return
      }
    } else {
      // Cases applicable to the org cloud subdomain based instances `subdomain.hoppscotch.io`

      // Reserved routes that need not be blocked
      // TODO: Include `r-id` & `e-id` in the list with relevant auth handlers existing at source and ensure members not having access to the organization are blocked
      if (["enter", "join-team", "join-org"].includes(to.name as string)) {
        return
      }

      // Top level check
      // Admin dashboard only supports dark mode currently
      // While navigating back to the app, recent preference is retained
      if (to.path.startsWith("/admin")) {
        const rootElClass = document.documentElement.getAttribute("class")

        if (rootElClass === "light" || rootElClass === "black") {
          document.documentElement.setAttribute("class", "dark")
        }
      } else {
        const persistenceService = getService(PersistenceService)
        const settings = await persistenceService.getNullable<{
          BG_COLOR?: string
        }>(STORE_KEYS.SETTINGS)

        const bgColorPreference = settings?.BG_COLOR ?? ""

        document.documentElement.setAttribute("class", bgColorPreference)
      }

      const organizationService = getService(OrganizationService)
      const orgInfo = await organizationService.getOrganizationInfo()

      // Allow the user to stay at the `/orgs/success` route in case the payment is in pending state
      // and the organization hasn't been created yet
      // Login prompts are in place as well
      if (to.name === "orgs-success") {
        return
      }

      // Prevent infinite loop with the above redirect
      if (_from.name === "orgs-success" && to.name === "orgs-invalid") {
        return
      }

      if (orgInfo?.error) {
        if (orgInfo.error === "auth/fail") {
          // Prevent infinite loop while redirecting to the `/admin` route below
          if (to.name === "admin") {
            return
          }

          if (to.path.startsWith("/admin")) {
            router.push("/admin")
            return
          }

          // Prevent getting into an infinite loop after redirecting to `/orgs/login-required` route
          if (to.name === "orgs-login-required") {
            return
          }

          router.push({
            name: "orgs-login-required",
          })

          return
        }

        if (orgInfo.error === "organization/organization_member_not_admin") {
          // The Admin dashboard login page has handler non privileged users
          if (to.path.startsWith("/admin/")) {
            router.push("/admin")
            return
          }

          // `/orgs/invalid` route should be blocked on valid subdomain instances
          // TODO: Update layout to `default`
          if (
            to.path.startsWith("/orgs") &&
            to.name !== "orgs-login-required" &&
            to.matched[0].components
          ) {
            to.matched[0].components.default = ErrorPage

            return
          }

          // An organization member without Admin access can access the API client
          return
        }

        // `organization` GQL query can fail while logging out from the Admin dashboard with `/auth/fail` that will be handled separately
        if (_from.path.startsWith("/admin")) {
          return
        }

        // Prevent infinite loop while redirecting to the `/orgs/invalid` route
        if (
          (_from.path === "/" || _from.name === "orgs-login-required") &&
          to.name === "orgs-invalid"
        ) {
          return
        }

        // Organization doesn't exist
        router.push("/orgs/invalid")
      }

      // This redirect helps prevent the need for a full page reload while logging out
      if (
        _from.name === "orgs-login-required" &&
        to.name === "index" &&
        !loadingCurrentUser.value &&
        !currentUser.value
      ) {
        router.push("/orgs/login-required")
        return
      }

      // `/orgs` & `/device-login` route exists only in the default cloud instance
      // TODO: Enable `/admin/authentication` route once e2e implementation is done
      // `/orgs/invalid` route should be blocked on valid subdomain instances
      // TODO: Update layout to `default`
      if (
        [
          "orgs",
          "orgs-invalid",
          "admin-authentication",
          "device-login",
        ].includes(to.name as string) &&
        to.matched[0].components
      ) {
        to.matched[0].components.default = ErrorPage

        return
      }

      if (to.path.startsWith("/admin/")) {
        // Redirect to the login page if the user is not logged in
        if (!loadingCurrentUser.value && !currentUser.value) {
          router.push("/admin")
          return
        }

        // `orgDomain` field being absent indicates GQL query fetching base organization info failed
        // The user is a not a member or not an Admin, etc
        // Redirect to the login page
        if (!orgInfo?.orgDomain) {
          router.push("/admin")
          return
        }

        // Redirect to the dashboard if a valid user lands on the `/admin` route
        if (to.name === "admin") {
          router.push("/admin/dashboard")
          return
        }
      }

      return
    }
  },
}
