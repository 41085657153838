import { getService } from "@hoppscotch/common/modules/dioc"
import { OrganizationPlatformDef } from "@hoppscotch/common/platform/organization"

import { isDefaultCloudInstance } from "@platform-helpers/utils/cloud-for-orgs"
import { OrganizationService } from "@platform-services/organization.service"

export const def: OrganizationPlatformDef = {
  isDefaultCloudInstance,
  getOrgInfo: async () => {
    const organizationService = getService(OrganizationService)

    const orgInfo = await organizationService.getOrganizationInfo()

    if (!orgInfo) {
      return null
    }

    const { orgID, orgDomain } = orgInfo

    return { orgID, orgDomain }
  },
  getRootDomain: () => {
    // On staging it follow the convention `subdomain.cloud-orgs-staging.deploychan.hoppscotch.com`
    if (
      window.location.hostname === "hoppscotch.io" ||
      window.location.hostname === "localhost"
    ) {
      return "hoppscotch.io"
    }

    return "cloud-orgs-staging.deploychan.hoppscotch.com"
  },
  initiateOnboarding: () => {
    // TODO: Use `vue-router` instead
    window.location.href = "/orgs"
  },
}
